import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const DropDownMenu = ({
  dropDownMenuIsOpen,
  dropDownMenuAnimationDurationBase,
  handleDropDownMenuLinkClick,
  handleSectionChange,
  activeAnchor,
  toggleDropDownMenu,
  viewportWidth,
  toggleModal
}) => {
  return (
    <Wrapper
      dropDownMenuIsOpen={dropDownMenuIsOpen}
      dropDownMenuAnimationDurationBase={dropDownMenuAnimationDurationBase}
      onClick={toggleDropDownMenu}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <div>
            <DropDownMenuPageLink
              to="/"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/');
              }}
              color="--amy-light-blue">
              Patient Stories{viewportWidth > 999 && <br />} +{viewportWidth > 999 && <br />}{' '}
              Resources
            </DropDownMenuPageLink>
            <DropDownMenuSubSectionLink
              to="/#james-and-pat-coyles-story"
              isActive={activeAnchor === '#james-and-pat-coyles-story'}
              activeColor="--amy-light-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/#james-and-pat-coyles-story');
              }}>
              James and Pat Coyle&apos;s Story
            </DropDownMenuSubSectionLink>
            <DropDownMenuSubSectionLink
              to="/#rosalines-story"
              isActive={activeAnchor === '#rosalines-story'}
              activeColor="--amy-light-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/#rosalines-story');
              }}>
              Rosaline&apos;s Story
            </DropDownMenuSubSectionLink>
            <DropDownMenuSubSectionLink
              to="/#other-patient-stories"
              isActive={activeAnchor === '#other-patient-stories'}
              activeColor="--amy-light-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/#other-patient-stories');
              }}>
              Other Patient Stories
            </DropDownMenuSubSectionLink>
            <DropDownMenuSubSectionLink
              to="/#resources"
              isActive={activeAnchor === '#resources'}
              activeColor="--amy-light-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/#resources');
              }}>
              Resources
            </DropDownMenuSubSectionLink>
          </div>

          <div>
            <DropDownMenuPageLink
              to="/genetics-and-t80a-roots/"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/genetics-and-t80a-roots/');
              }}
              color="--amy-dark-blue">
              Genetics{viewportWidth > 999 && <br />} +{viewportWidth > 999 && <br />} T80A Roots
            </DropDownMenuPageLink>
            <DropDownMenuSubSectionLink
              to="/genetics-and-t80a-roots/#genetics"
              isActive={activeAnchor === '#genetics'}
              activeColor="--amy-dark-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/genetics-and-t80a-roots/#genetics');
              }}>
              Genetics
            </DropDownMenuSubSectionLink>
            <DropDownMenuSubSectionLink
              to="/genetics-and-t80a-roots/#t80a-roots"
              isActive={activeAnchor === '#t80a-roots'}
              activeColor="--amy-dark-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/genetics-and-t80a-roots/#t80a-roots');
              }}>
              T80A Roots
            </DropDownMenuSubSectionLink>
          </div>

          <div>
            <DropDownMenuPageLink
              to="/symptoms-and-diagnosis/"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/symptoms-and-diagnosis/');
              }}
              color="--amy-blue">
              Symptoms{viewportWidth > 999 && <br />} +{viewportWidth > 999 && <br />} Diagnosis
            </DropDownMenuPageLink>
            <DropDownMenuSubSectionLink
              to="/symptoms-and-diagnosis/#about-amyloidosis"
              isActive={activeAnchor === '#about-amyloidosis'}
              activeColor="--amy-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/symptoms-and-diagnosis/#about-amyloidosis');
              }}>
              About Amyloidosis
            </DropDownMenuSubSectionLink>
            <DropDownMenuSubSectionLink
              to="/symptoms-and-diagnosis/#symptoms"
              isActive={activeAnchor === '#symptoms'}
              activeColor="--amy-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/symptoms-and-diagnosis/#symptoms');
              }}>
              Symptoms
            </DropDownMenuSubSectionLink>
            <DropDownMenuSubSectionLink
              to="/symptoms-and-diagnosis/#diagnosis"
              isActive={activeAnchor === '#diagnosis'}
              activeColor="--amy-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/symptoms-and-diagnosis/#diagnosis');
              }}>
              Diagnosis
            </DropDownMenuSubSectionLink>
            <DropDownMenuSubSectionLink
              to="/symptoms-and-diagnosis/#speaking-with-a-doctor"
              isActive={activeAnchor === '#speaking-with-a-doctor'}
              activeColor="--amy-blue"
              onClick={(e) => {
                handleSectionChange({ sectionManuallySelected: true });
                handleDropDownMenuLinkClick(e, '/symptoms-and-diagnosis/#speaking-with-a-doctor');
              }}>
              Speaking With{viewportWidth > 999 && <br />} A Doctor
            </DropDownMenuSubSectionLink>
          </div>
          {viewportWidth < 999 && (
            <div>
              <HCPLink onClick={toggleModal}>
                <p>
                  If you are a healthcare professional and would like further information please
                  <br />
                  <span>Click Here</span>
                </p>
              </HCPLink>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  left: 0;
  padding: 0.938em;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;

  ${({ dropDownMenuAnimationDurationBase, dropDownMenuIsOpen }) =>
    dropDownMenuIsOpen
      ? css`
          background-color: var(--amy-lightest-blue-tranparent);
          pointer-events: auto;
          transition: background-color ${dropDownMenuAnimationDurationBase * 2}ms ease-in-out;

          > div {
            height: 100%;
            transition: height ${dropDownMenuAnimationDurationBase * 2}ms
              ${dropDownMenuAnimationDurationBase}ms ease-in-out;

            > div {
              opacity: 1;
              transition: opacity ${dropDownMenuAnimationDurationBase}ms
                ${dropDownMenuAnimationDurationBase * 2 + dropDownMenuAnimationDurationBase / 2}ms
                ease-in-out;
            }
          }
        `
      : css`
          background-color: transparent;
          pointer-events: none;
          transition: background-color ${dropDownMenuAnimationDurationBase * 2}ms
            ${dropDownMenuAnimationDurationBase * 2 + dropDownMenuAnimationDurationBase / 2}ms
            ease-in-out;

          > div {
            height: 0;
            transition: height ${dropDownMenuAnimationDurationBase * 2}ms
              ${dropDownMenuAnimationDurationBase}ms ease-in-out;

            > div {
              opacity: 0;
              transition: opacity ${dropDownMenuAnimationDurationBase}ms ease-in-out;
            }
          }
        `};

  > div {
    background-color: #fff;
    width: 100%;
    max-height: 48em;
    max-width: 61.25em;
    margin: 0 auto;
    overflow-y: scroll;

    @media screen and (min-width: 48em) {
      max-height: 44em;
    }

    @media screen and (min-width: 62.5em) {
      max-height: 39.063em;
      overflow-y: hidden;
    }

    > div {
      position: relative;
      top: 20.25%;

      @media screen and (min-width: 23.438em) {
        top: 22.9%;
      }

      @media screen and (min-width: 40.625em) {
        top: 17.25%;
      }

      @media screen and (min-width: 48em) {
        top: 19%;
      }

      @media screen and (min-width: 62.5em) {
        display: flex;
        justify-content: space-between;
        max-width: 86.94%;
        top: 11em;
        margin: 0 auto;
      }

      > div {
        align-items: center;
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          a:last-child {
            margin-bottom: 1.85em;
          }
        }
      }
    }
  }
`;

const DropDownMenuPageLink = styled(Link)`
  color: ${({ color }) => css`var(${color})`};
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: none;
  margin-bottom: 0.725em;

  @media screen and (min-width: 22.5em) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: 40.625em) {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 62.5em) {
    font-size: 2rem;
    text-align: center;
    line-height: 1em;
    margin-bottom: 1.637em;
  }
`;

const DropDownMenuSubSectionLink = styled(Link)`
  color: #000;
  font-weight: 600;
  font-size: 0.85rem;
  text-decoration: none;
  margin-bottom: 0.91em;

  @media screen and (min-width: 22.5em) {
    font-size: 1rem;
  }

  @media screen and (min-width: 40.625em) {
    font-size: 1.15rem;
  }

  @media screen and (min-width: 62.5em) {
    font-size: 1.5rem;
    text-align: center;
  }

  ${({ isActive, activeColor }) =>
    isActive &&
    css`
      color: var(${activeColor});
      font-weight: 700;
    `};
`;

const HCPLink = styled.button`
  box-shadow: none;
  width: 100%;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  align-items: center;

  color: #000;
  background: transparent;
  font-size: 1.125rem;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.875rem;
  height: 5rem;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  padding: 0.75rem 1.25rem;
  span {
    font-size: 1rem;
    text-decoration: underline;
    line-height: 2rem;
  }
`;

export default DropDownMenu;
