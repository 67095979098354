import * as React from 'react';

function SvgMicroscopeIcon(props) {
  return (
    <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M111.783 122.357c-2.417 0-4.23 1.813-4.23 4.23 0 2.416 1.813 4.229 4.23 4.229 2.417 0 4.229-1.813 4.229-4.229 0-2.417-1.812-4.23-4.229-4.23z"
        fill="#B6DCF5"
      />
      <path
        d="M100 0C44.713 0 0 44.713 0 100s44.713 100 100 100 100-44.713 100-100S155.287 0 100 0zm6.344 34.743c.303-1.208 1.813-1.51 2.719-1.208l11.783 4.834c1.208.302 1.511 1.812 1.208 2.719l-4.229 9.97-15.71-6.345 4.229-9.97zM82.175 82.175l12.991-31.42c.302-1.208 1.813-1.51 2.72-1.208l19.637 8.157c1.208.302 1.51 1.813 1.208 2.719l-2.719 6.344c13.595 5.74 23.263 19.034 23.263 34.744 0 9.063-3.323 17.522-8.459 23.867-.605-8.157-6.345-15.106-14.2-17.221.907-2.115 1.511-4.23 1.511-6.646 0-6.95-4.23-12.69-10.272-15.408l-2.115 5.74c-.302 1.208-1.813 1.51-2.719 1.208l-19.637-8.157c-1.209-.302-1.813-1.812-1.209-2.719zm-3.323 19.336l3.927-9.668 15.71 6.344-3.927 9.668c-.302 1.208-1.813 1.511-2.72 1.208L80.06 104.23c-1.208-.605-1.812-1.813-1.208-2.719zm70.997 57.099c0 1.209-.906 2.115-2.115 2.115H52.266c-1.209 0-2.115-.906-2.115-2.115v-6.344c0-1.209.906-2.115 2.115-2.115H67.07v-4.23c0-1.208.906-2.114 2.114-2.114h21.148c1.209 0 2.115.906 2.115 2.114v4.23h6.344v-19.033H63.142c-1.209 0-2.115-.907-2.115-2.115v-4.23c0-1.208.907-2.114 2.115-2.114h36.556c1.813-4.834 6.344-8.46 12.084-8.46 6.949 0 12.689 5.741 12.689 12.689 0 12.689 10.574 23.263 23.263 23.263 1.209 0 2.115.906 2.115 2.115v6.344z"
        fill="#B6DCF5"
      />
    </svg>
  );
}

export default SvgMicroscopeIcon;
