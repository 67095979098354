import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ScrollPercentage } from 'react-scroll-percentage';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import {
  LargeImg,
  LineBreak,
  Paragraph,
  SubSectionH2,
  SubSectionH3,
  SectionTitle,
  LinksToLearnMore,
  Note,
  VideoPlayer,
  SEO
} from 'components';
import donegalHighlightedOnIrelandMap from 'images/GeneticsAndT60aRoots/donegal_highlighted_on_Ireland_map.svg';

const GeneticsAndT60aRoots = ({
  checkIfSectionIsInView,
  data,
  handleCarousel,
  location,
  populateAsideWithSectionTitlesFromCurrentPage,
  scrollToAnchor,
  updateActiveAnchor,
  viewportWidth
}) => {
  const [playingVideo, setPlayingVideo] = useState(null);

  useEffect(() => {
    if (viewportWidth >= 768) {
      populateAsideWithSectionTitlesFromCurrentPage();
    }
  }, [viewportWidth]);

  return (
    <section>
      <SEO
        pageSpecificTitle="Genetics and T80A Roots"
        pageSpecificDescription="Learn about hATTR amyloidosis and the T60A mutation associated with it."
        pathname={location.pathname}
      />
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#genetics')}>
        <SectionTitle id="genetics">Genetics</SectionTitle>
      </ScrollPercentage>
      <SubSectionH2>Hereditary ATTR (hATTR) amyloidosis - a genetic condition</SubSectionH2>
      <Paragraph>
        hATTR amyloidosis can be passed along generations. A person only needs to inherit one copy
        of the gene mutation from one parent in order to develop the disease. This pattern of
        inheritance is known as &apos;autosomal dominance&apos;.
      </Paragraph>
      <LineBreak customHeight="2.25em" />
{/*       <VideoPlayer
        vimeoCode="475480724"
        paddingTop="56.097560975609756%"
        title="Mary Reilly lecture"
        playingVideo={playingVideo}
        setPlayingVideo={setPlayingVideo}
        thumbnail={data.maryReillyLectureVideoThumbnail}
      /> */}
      <SubSectionH3>Autosomal dominant inheritance</SubSectionH3>
      <Paragraph>
        As shown in the diagram below, genes are located on structures known as chromosomes. Every
        person has two copies of the TTR gene, one inherited from each parent. When one parent
        carries an autosomal dominant mutation, each individual child will have a 50% chance of
        inheriting the mutation.
      </Paragraph>
      <br />
      <Paragraph>
        <b>
          It&apos;s important to know that not all individuals with the mutation will develop signs
          and symptoms of the disease, and the age of onset and symptoms can vary. It can be
          upsetting to hear that your relatives may be at risk, and you should discuss this with
          your healthcare professional.
        </b>
      </Paragraph>
      <InheritenceWrapper>
        <InheritenceTreeImg
          fluid={data.inheritenceTree.childImageSharp.fluid}
          alt="gene inheritence tree"
        />
        <LineBreak />
        <InheritenceIndicator>
          <div>
            <IndicatorColor backgroundColor="--amy-blue" />
            Affected
          </div>
          <div>
            <IndicatorColor backgroundColor="--amy-lightest-blue" />
            Unaffected
          </div>
          <div>
            <IndicatorColor backgroundColor="--amy-background-dark-grey" />
            Chromosome
          </div>
        </InheritenceIndicator>
      </InheritenceWrapper>
      <SubSectionH3>Symptoms may vary among people with hATTR amyloidosis</SubSectionH3>
      <Paragraph>
        Within your family, each person who develops the condition may experience different
        symptoms, even if he or she has the same mutation.
      </Paragraph>
      <Paragraph>
        Although hATTR amyloidosis is a hereditary condition, some families may be unaware of a
        history of the condition in their family. So while your family members may not have been
        diagnosed with the condition, they may have experienced symptoms similar to yours. It’s
        important to talk to your family about any family history that may be related to hATTR
        amyloidosis.
      </Paragraph>
      <LineBreak customHeight="3.55em" />
      <LargeImg
        sources={[
          data.donegalTownMobile.childImageSharp.fluid,
          data.donegalTown.childImageSharp.fluid
        ]}
        alt={data.donegalTown.name}
        caption="Image - Donegal"
      />
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#t80a-roots')}>
        <SectionTitle id="t80a-roots">
          T80A Roots (formerly known as T60A)
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              updateActiveAnchor(null);
              scrollToAnchor('references');
            }}>
            <sup style={{ fontSize: '0.875rem', verticalAlign: 'top', lineHeight: '0.5em', marginLeft: '0.25rem' }}>1</sup>
          </Link>
        </SectionTitle>
      </ScrollPercentage>
      <SubSectionH2>
        A common form of hATTR amyloidosis seen in Ireland and the UK.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup style={{ fontSize: '1rem', verticalAlign: 'top', lineHeight: '2em' }}>2</sup>
        </Link>
      </SubSectionH2>
      <Paragraph>
        One of the most common mutations associated with hATTR amyloidosis in Ireland and the UK is called T80A
        (formerly known as T60A).
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>1</sup>
        </Link>
        &nbsp;It occurs more frequently in people with Irish ancestry.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>2</sup>
        </Link>
        &nbsp;Symptoms usually appear between ages 45 and 78, most often after age 60.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>3</sup>
        </Link>
        &nbsp;It is slightly more common in men than in women.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>3</sup>
        </Link>
      </Paragraph>
      <SubSectionH3>
        The history of the T80A mutation&nbsp;{viewportWidth >= 1100 && <br />}associated with hATTR
        amyloidosis
      </SubSectionH3>
      <Paragraph>
        The genetic mutation causing hATTR T80A amyloidosis was first identified in an Irish family
        in the 1980s.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>2</sup>
        </Link>
        &nbsp;A cluster of cases have been identified in the County Donegal region of North West
        Ireland, where it has been estimated that 1% of the population have this mutation.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>2</sup>
        </Link>
        &nbsp;Cases have been described all around the world, but often UK patients with this
        condition have Irish ancestry.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>2</sup>
        </Link>
      </Paragraph>
      <LineBreak />
      <NoteAndMapWrapper>
        <DonegalHighlightedOnIrelandMap
          src={donegalHighlightedOnIrelandMap}
          alt="Donegal highlighted on Ireland map"
        />
        <Note fontSize="1rem" marginTop="4.25em" lineHeight="1.5em">
          <p>
            <b>County Donegal, Ireland</b>
            <br />
            (where 1% of the population carry the hATTR amyloidosis T80A mutation).
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault();
                updateActiveAnchor(null);
                scrollToAnchor('references');
              }}>
              <sup style={{ fontSize: '.625rem' }}>2</sup>
            </Link>
          </p>
        </Note>
      </NoteAndMapWrapper>
      <SubSectionH3>What causes hATTR T80A amyloidosis?</SubSectionH3>
      <Paragraph>
        This condition is caused by a mutation in the TTR gene which results in the production of an
        abnormal (‘variant’) TTR protein called T80A. A mutation is a permanent change in the
        sequence of DNA which makes up the genes in all cells in the body. The DNA acts like a
        blueprint or recipe for building the proteins that make up the body. The proteins are made
        up of strings of amino acids, assembled in a precise order. The DNA determines the order in
        which amino acids are assembled. In people with the T80A mutation, an amino acid called
        threonine is replaced by an amino acid called alanine at position number 80 in the TTR
        molecule. This gene mutation can cause the TTR protein to change shape and
        &apos;misfold&apos;. This new shape means the protein gathers together in structures known
        as &apos;amyloid fibrils&apos;, which build up in various parts of the body. This can result
        in a variety of symptoms.
      </Paragraph>
      <SubSectionH3>Learn more about</SubSectionH3>
      <LinksToLearnMore
        handleCarousel={handleCarousel}
        updateActiveAnchor={updateActiveAnchor}
        viewportWidth={viewportWidth}
        links={['/symptoms-and-diagnosis/', '/']}
      />
      <Footnotes id="references">
        <span>References</span>
        <br />
        1. Gillmore JD et al. Adv Ther. 2022;39(6):2292-2301
        <br />
        2. Reilly MM, Staunton H, Harding AE. Familial amyloid polyneuropathy (TTR Ala 60) in North
        West Ireland: a clinical, genetic, and epidemiological study. J Neurol Neurosurg Psychiatry.
        1995; 59:45–49.
        <br />
        3. Sattianayagam PT, Hahn AF, Whelan CJ, et al. Cardiac phenotype and clinical outcome of
        familial amyloid polyneuropathy associated with transthyretin alanine 60 variant. Eur Heart
        J. 2012; 33:1120–1127.
        <br />
        4. Adams D, Ando Y, Beirão JM, et al. Expert consensus recommendations to improve diagnosis
        of ATTR amyloidosis with polyneuropathy [published online ahead of print, 2020 Jan 6]. J
        Neurol. 2020;10.1007/s00415-019-09688-0.
        <br />
        <br />
        <span>Abbreviations</span>
        <br />
        ATTR: Transthyretin amyloidosis
        <br />
        hATTR: hereditary ATTR (hATTR)
        <br />
        MRI: magnetic resonance imaging
      </Footnotes>
    </section>
  );
};

const NoteAndMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    max-width: 22.5em;
    width: 100%;
  }

  @media screen and (min-width: 62.5em) {
    display: block;
    margin: 3.4em auto 4.225em;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: unset;
      transform: unset;
    }

    > img {
      margin-top: 4.8em;
      margin-left: 29.268292682926827%;
    }
  }
`;

const DonegalHighlightedOnIrelandMap = styled.img`
  max-width: 27.5em;
  margin: 0 auto;
  width: 100%;
`;

const InheritenceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4.7em auto 2.6em;

  @media screen and (min-width: 75em) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const InheritenceTreeImg = styled(Img)`
  width: 100%;
  max-width: 36.25em;
`;

const IndicatorColor = styled.span`
  background-color: var(${({ backgroundColor }) => backgroundColor});
  border-radius: 50%;
  display: inline-block;
  height: 1.5em;
  margin-right: 1.5em;
  width: 1.5em;
`;

const InheritenceIndicator = styled.div`
  background-color: #e5e5e550;
  display: flex;
  flex-direction: column;
  height: 10em;
  justify-content: center;
  padding-left: 1.5em;
  width: 13.75em;

  > div {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1.313em;
    }
  }
`;

const Footnotes = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  margin-top: 8.167em;

  span {
    text-transform: uppercase;
    text-decoration: underline;
  }
`;

export const GeneticsAndT60aRootsQuery = graphql`
  {
    inheritenceTree: file(relativePath: { eq: "GeneticsAndT60aRoots/inheritence_tree.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 580, maxHeight: 580) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    donegalTownMobile: file(relativePath: { eq: "GeneticsAndT60aRoots/donegal_town_mobile.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 737, maxHeight: 445) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    donegalTown: file(relativePath: { eq: "GeneticsAndT60aRoots/donegal_town.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 820, maxHeight: 460, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    maryReillyLectureVideoThumbnail: file(
      relativePath: { eq: "GeneticsAndT60aRoots/mary_reilly_lecture_video_thumbnail.jpeg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 820, maxHeight: 460, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default GeneticsAndT60aRoots;
