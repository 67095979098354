import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Circle from 'images/Shared/Circle';
import { Link } from 'gatsby';
import AlnylamLogo from 'images/Shared/AlnylamLogo';
import FooterTrianglesMobile from 'images/Shared/FooterTrianglesMobile';

const Footer = ({ viewportWidth, pathname }) => (
  <>
    <div style={{ maxHeight: '16.25em', overflow: 'hidden' }}>
      <Wrapper>
        {viewportWidth < 550 ? (
          <StyledFooterTrianglesMobile />
        ) : (
          <>
            <StyledCircle fill="--amy-dark-blue" bottom="calc(5.938em - 1.5em)" left="-1.625em" />
            <StyledCircle
              fill="--amy-lightest-blue"
              bottom="calc(8.7em - 1.5em)"
              left="calc(92.361111111111111% - 1.5em)"
            />
            <StyledCircle fill="--amy-dark-blue" bottom="-1.5em" left="calc(100% - 1.5em)" />
            <svg
              className="yellow"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none">
              <polygon fill="#009AD8" points="100,100 100,0 0,33.5" />
            </svg>
            <svg
              className="orange"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none">
              <polygon fill="#0279A9" points="100,100 100,0 0,3.5" />
            </svg>
          </>
        )}
        <FooterContent>
          <LogoAndLinksWrapper>
            <a href="https://www.alnylam.com/" aria-label="Alnylam logo">
              <StyledAlnylamLogo alt="Alnylam logo" />
            </a>
            <Links>
              {/* <Link to="/terms-of-use/">Terms of Use</Link> */}
              <a href=" https://alnylampolicies.com/en-gb/privacy" target="__blank">
                Privacy Policy
              </a>
              <a href="https://alnylampolicies.com/en-gb/legal" target="__blank">
                Legal
              </a>
            </Links>
          </LogoAndLinksWrapper>
          <SmallPrint>
            © {new Date().getFullYear()} Alnylam Pharmaceuticals, Inc. All rights reserved. Designed
            and developed by{' '}
            <a style={{ color: '#000' }} href="https://www.agencyx.ie">
              Agency X
            </a>{' '}
            on behalf of Alnylam Pharmaceuticals. &nbsp;{viewportWidth >= 375 && <br />}Job Code:
            {pathname === '/symptoms-and-diagnosis/'
              ? ' NP-UK-00135'
              : pathname === '/genetics-and-t80a-roots/'
              ? ' NP-UK-00134'
              : ' NP-UK-00133'}
            . Date of Preparation: December 2022.
          </SmallPrint>
        </FooterContent>
      </Wrapper>
    </div>
  </>
);

const Wrapper = styled.footer`
  align-items: center;
  background-color: var(--amy-lightest-blue);
  display: flex;
  height: 25.33vw;
  justify-content: center;
  margin-top: 7.5em;
  max-height: 5.938em;
  position: relative;
  width: 100%;

  svg.yellow,
  svg.orange {
    bottom: 0;
    height: 8.75em;
    position: absolute;
  }

  svg.yellow {
    width: 100%;
  }

  svg.orange {
    right: 0;
    width: 7.638888888888889%;
  }
`;

const FooterContent = styled.div`
  max-width: 84.222em;
  padding: 0 7.1%;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: 84.222em) {
    margin: 0 auto;
    max-width: 73.75em;
    padding: 0;
  }
`;

const LogoAndLinksWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 9em;
  width: 100%;

  a {
    color: #000;
    font-size: 0.7rem;
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 1.2em;
    text-align: center;
    text-decoration: none;

    &:first-of-type {
      margin-right: 1em;
    }
  }

  @media screen and (min-width: 23.438em) {
    max-width: 11.5em;

    a {
      font-size: 0.875rem;
      text-decoration: none;
    }
  }
`;

const SmallPrint = styled.p`
  font-size: 0.55rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  width: 100%;

  @media screen and (min-width: 23.438em) {
    font-size: 0.625rem;
  }

  @media screen and (min-width: 25em) {
    margin-top: 0.25em;
  }
`;

const StyledAlnylamLogo = styled(AlnylamLogo)`
  width: 6.625em;

  @media screen and (min-width: 48em) {
    width: 8em;
  }
`;

const StyledFooterTrianglesMobile = styled(FooterTrianglesMobile)`
  bottom: 0;
  max-width: 23.438em;
  position: absolute;
  right: 0;
  width: 100%;
`;

const StyledCircle = styled(Circle)`
  position: absolute;
  width: 3em;
  z-index: 1;

  ${({ fill, bottom, left }) => css`
    bottom: ${bottom};
    left: ${left};

    circle {
      fill: var(${fill});
    }
  `}
`;

export default Footer;
