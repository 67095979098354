import * as React from 'react';

function SvgHcpIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.283 0h-6.336v2.43h3.84L9.079 9.132l1.76 1.76 6.71-6.705v3.838H20v-6.31C20 .77 19.23 0 18.283 0zm-.353 9.125v7.282A3.598 3.598 0 0114.334 20H3.596A3.598 3.598 0 010 16.407V5.63a3.598 3.598 0 013.596-3.593h7.217v1.942H3.596c-.911 0-1.652.741-1.652 1.651v10.778c0 .91.74 1.651 1.652 1.651h10.738c.91 0 1.652-.74 1.652-1.65V9.124h1.944z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgHcpIcon;
