import * as React from 'react';

function SvgCircle(props) {
  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={40} cy={40} r={36.5} fill="#fff" stroke="#fff" strokeWidth={7} />
    </svg>
  );
}

export default SvgCircle;
