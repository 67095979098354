import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import throttle from 'lodash.throttle';
import { scroller } from 'react-scroll';
import { Header, Hero, Footer, Aside, DropDownMenu, RenderWhenReady } from 'components';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { GlobalStyle } from 'shared_styles';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import CookieConsent from 'react-cookie-consent';

class Layout extends PureComponent {
  state = {
    dropDownMenuIsOpen: false,
    patientStoryDropMenuIsOpen: false,
    dropDownMenuAnimationDurationBase: 350,
    activeAnchor: null,
    sectionManuallySelected: null,
    visible: false
  };

  handleWindowResize = throttle(() => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    this.setState((prevState) => {
      if (viewportWidth !== undefined && viewportWidth !== prevState.viewportWidth) {
        return {
          viewportWidth
        };
      }
    });
    this.setState((prevState) => {
      if (viewportHeight !== undefined && viewportHeight !== prevState.viewportHeight) {
        return {
          viewportHeight
        };
      }
    });
  }, 100);

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);

    // this.handleOverlay();

    this.handleCarousel(this.props.location.pathname);

    window.addEventListener('popstate', this.handleBrowserBackAndForwardNavButtons);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { activeAnchor } = this.state;
      if (activeAnchor) {
        setTimeout(() => {
          this.scrollToAnchor(activeAnchor.slice(1));
          this.handleSectionChange({ sectionManuallySelected: null });
        }, 425);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('popstate', this.handleBrowserBackAndForwardNavButtons);
  }

  handleBrowserBackAndForwardNavButtons = (e) => {
    this.updateActiveAnchor(null);
    this.handleCarousel(e.path[0].location.pathname);
    window.scroll(0, 0);
  };

  handleSectionChange = ({ sectionManuallySelected }, anchor) => {
    this.setState(
      {
        sectionManuallySelected
      },
      () => {
        if (!this.state.sectionManuallySelected && anchor !== undefined) {
          this.updateActiveAnchor(anchor);
        }
      }
    );
  };

  handleCarousel = (href) => {
    let selectedCarouselSlide;

    switch (href) {
      case '/':
        selectedCarouselSlide = 0;
        break;
      case '/genetics-and-t80a-roots/':
        selectedCarouselSlide = 1;
        break;
      case '/symptoms-and-diagnosis/':
        selectedCarouselSlide = 2;
        break;
      default:
        break;
    }

    this.setState({
      selectedCarouselSlide
    });
  };

  handlePatientStoryDropMenuIsOpen = (e) => {
    e.preventDefault();
    this.togglePatientStoryDropDownMenu();
  };

  handleDropDownMenuLinkClick = (e, nextPathname) => {
    e.preventDefault();

    this.toggleDropDownMenu();

    const { dropDownMenuAnimationDurationBase } = this.state;

    setTimeout(() => {
      const index = nextPathname.indexOf('/', 1) + 1;

      const nextPathnameWithoutAnchor = nextPathname.slice(0, index) || '/';

      const anchor =
        nextPathnameWithoutAnchor === '/'
          ? nextPathname.slice(index + 1)
          : nextPathname.slice(index);

      if (this.props.location.pathname === nextPathnameWithoutAnchor) {
        this.scrollToAnchor(anchor.slice(1));
        setTimeout(() => {
          this.handleSectionChange({ sectionManuallySelected: null });
        }, 1000);
      } else {
        const { pageYOffset } = window;
        if (pageYOffset) {
          this.scrollToAnchor('top');
        }

        setTimeout(
          () => {
            navigate(nextPathnameWithoutAnchor);
          },
          pageYOffset === 0 ? 425 : 1000
        );

        setTimeout(
          () => this.handleCarousel(nextPathnameWithoutAnchor),
          pageYOffset === 0 ? 0 : 925
        );
      }

      this.updateActiveAnchor(anchor);
    }, dropDownMenuAnimationDurationBase * 3);
  };

  // handleOverlay = () => {
  //   if (window.___INITIAL_RENDER) {
  //     window.fadeOutOverlay();
  //   }
  // };

  populateAsideWithSectionTitlesFromCurrentPage = () => {
    const sectionTitlesFromCurrentPage = Array.from(document.querySelectorAll('.section-title'))
      .filter((element) => !element.classList.contains('dont-include-in-aside'))
      .map((element) => element.innerText.toLowerCase());

    this.setState({
      sectionTitlesFromCurrentPage
    });
  };

  updateActiveAnchor = (anchor) => {
    this.setState((prevState) => {
      if (prevState.activeAnchor !== anchor) {
        return {
          activeAnchor: anchor
        };
      }
    });
  };

  scrollToAnchor = (anchor) => {
    scroller.scrollTo(anchor, {
      duration: 1000,
      delay: 0,
      offset: this.props.viewportWidth < 768 ? -82 : -164,
      smooth: 'easeInOutQuint'
    });
  };

  toggleDropDownMenu = () => {
    this.setState((prevState) => ({
      dropDownMenuIsOpen: !prevState.dropDownMenuIsOpen
    }));
  };

  togglePatientStoryDropDownMenu = () => {
    this.setState((prevState) => ({
      patientStoryDropMenuIsOpen: !prevState.patientStoryDropMenuIsOpen
    }));
  };

  modalToggle = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible
    }));
  };

  checkIfSectionIsInView = (percentage, anchor) => {
    const { sectionManuallySelected, viewportWidth } = this.state;
    if (
      viewportWidth >= 768 &&
      !sectionManuallySelected &&
      percentage > 0.45 &&
      percentage < 0.82
    ) {
      this.handleSectionChange({ sectionManuallySelected: false }, anchor);
    }
  };

  modalRedirect = () => {
    window.location = 'https://www.hattrmatters.com/en';
  };

  render() {
    const { children, location } = this.props;
    const { pathname } = location;
    const {
      activeAnchor,
      patientStoryDropMenuIsOpen,
      dropDownMenuIsOpen,
      dropDownMenuAnimationDurationBase,
      sectionTitlesFromCurrentPage,
      selectedCarouselSlide,
      viewportWidth,
      viewportHeight
    } = this.state;

    const childrenExtended = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        checkIfSectionIsInView: this.checkIfSectionIsInView,
        populateAsideWithSectionTitlesFromCurrentPage: this
          .populateAsideWithSectionTitlesFromCurrentPage,
        scrollToAnchor: this.scrollToAnchor,
        updateActiveAnchor: this.updateActiveAnchor,
        handleCarousel: this.handleCarousel,
        viewportWidth,
        viewportHeight
      })
    );

    return (
      <Wrapper>
        <GlobalStyle />
        <DropDownMenu
          activeAnchor={activeAnchor}
          dropDownMenuAnimationDurationBase={dropDownMenuAnimationDurationBase}
          dropDownMenuIsOpen={dropDownMenuIsOpen}
          handleDropDownMenuLinkClick={this.handleDropDownMenuLinkClick}
          handleSectionChange={this.handleSectionChange}
          toggleDropDownMenu={this.toggleDropDownMenu}
          viewportWidth={viewportWidth}
          toggleModal={this.modalToggle}
        />
        <Header
          dropDownMenuIsOpen={dropDownMenuIsOpen}
          handleCarousel={this.handleCarousel}
          toggleDropDownMenu={this.toggleDropDownMenu}
          viewportWidth={viewportWidth}
          toggleModal={this.modalToggle}
        />
        <Hero
          handleCarousel={this.handleCarousel}
          pathname={pathname}
          scrollToAnchor={this.scrollToAnchor}
          selectedCarouselSlide={selectedCarouselSlide}
          updateActiveAnchor={this.updateActiveAnchor}
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}
          patientStoryDropMenuIsOpen={patientStoryDropMenuIsOpen}
          handlePatientStoryDropMenuIsOpen={this.handlePatientStoryDropMenuIsOpen}
        />
        <main>
          {childrenExtended}
          {viewportWidth > 767 && (
            <Aside
              activeAnchor={activeAnchor}
              handleCarousel={this.handleCarousel}
              handleSectionChange={this.handleSectionChange}
              pathname={pathname}
              scrollToAnchor={this.scrollToAnchor}
              sectionTitlesFromCurrentPage={sectionTitlesFromCurrentPage}
              updateActiveAnchor={this.updateActiveAnchor}
              viewportWidth={viewportWidth}
            />
          )}
        </main>
        <Footer viewportWidth={viewportWidth} pathname={pathname} />
        <Rodal visible={this.state.visible} onClose={this.modalToggle} closeMaskOnClick>
          <p>
            I confirm I am a Healthcare Professional
            <input type="checkbox" onClick={this.modalRedirect} />
          </p>
        </Rodal>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          enableDeclineButton
          onDecline={() => alert('Cookies disabled')}
          onAccept={()=> initializeAndTrack(location)}
          disableStyles
          containerClasses="cookie-container"
          buttonClasses="cookie-button">
          <p>
            This website intends to use cookies to improve the site and its experience. By
            continuing to browse the site, you agree to accept our use of cookies. If you need more
            information and / or do not want cookies placed when using the site, visit our{' '}
            <a
              href="https://alnylampolicies.com/en-gb/cookies"
              style={{ color: 'white' }}
              target="blank">
              cookies page
            </a>{' '}
            . To learn about the company&apos;s policies, visit the policy page.
          </p>
        </CookieConsent>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 120em;
  position: relative;
  div.cookie-container {
    position: fixed;
    z-index: 10;
    bottom: 0;
    padding: 1.5em;
    background: #333;
    p {
      font-size: 0.75rem;
      line-height: 1%.5em;
      color: white;
    }

    button {
      margin-top: 1em;
      font-size: 1rem;
      text-decoration: underline;
      background: none;
      border: none;
      color: white;
      font-weight: normal;
      cursor: pointer;
      :first-child {
        margin-right: 0.5em;
      }
    }
  }
  div.rodal {
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    position: fixed;
    .rodal-dialog {
      width: 700px !important;
      max-width: 90% !important;
      font-weight: 600;
      font-size: 1em;
      line-height: 2em;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media screen and (min-width: 62.5em) {
        font-size: 1.5em;
        line-height: 3em;
      }
    }
    .rodal-close {
      top: -50px;
      right: 10px;
      background-color: #009AD8;
      border: 2px solid white;
      border-radius: 3px;
      width: 1.5em;
      height: 1.5em;
      @media screen and (min-width: 62.5em) {
        right: -50px;
        top: 0px;
        width: 1em;
        height: 1em;
      }
      :before,
      :after {
        border-radius: 0px;
        background: white;
        width: 60%;
        left: 20%;
      }
    }
    input[type='checkbox'] {
      width: 1em;
      height: 1em;
      margin-left: 1em;
      cursor: pointer;
      @media screen and (min-width: 62.5em) {
        margin-left: 2em;
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  @media screen and (min-width: 120.125em) {
    border: 0.063em solid var(--amy-dark-blue);
    border-width: 0 0.063em 0 0.063em;
  }

  main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: content-box;
    padding: 0 0.938em;
    max-width: 73.75em;
    margin-top: -2.5005em;

    @media screen and (min-width: 73.75em) {
      margin: -2.5005em auto 0;
    }

    > section,
    > aside {
      box-sizing: border-box;
    }

    > section {
      max-width: 51.25em;
      margin: 0 auto;

      @media screen and (min-width: 48em) {
        width: 69.49152542372882%;
        margin: 0;
      }
    }
  }
`;

export default Layout;
