import React, { PureComponent } from 'react';
import { Link, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import transition from 'styled-transition-group';
import hATTRLogo from 'images/Shared/HAttrLogo';
import AlnylamLogo from 'images/Shared/AlnylamLogo';
import HeaderTrianglesMobile from 'images/Shared/HeaderTrianglesMobile';
import Circle from 'images/Shared/Circle';
import HcpIcon from 'images/HcpIcon';
import MenuIconOpenSVG from 'images/menu_icon_open.svg';
import MenuIconCloseSVG from 'images/menu_icon_close.svg';

class Header extends PureComponent {
  render() {
    const { viewportWidth, toggleDropDownMenu, dropDownMenuIsOpen, handleCarousel, toggleModal } =
      this.props;
    return (
      <Wrapper>
        <div>
          {viewportWidth < 550 ? (
            <StyledHeaderTrianglesMobile />
          ) : (
            <>
              <StyledCircle fill="--amy-dark-blue" bottom="calc(100% - 1.5em)" left="-1.5em" />
              <StyledCircle
                fill="--amy-lightest-blue"
                bottom="-4.5em"
                left="calc(7.638888888888889% - 1.5em)"
              />
              <StyledCircle fill="--amy-dark-blue" bottom="-1.5em" left="calc(100% - 1.5em)" />
              <svg
                className="yellow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="none">
                <polygon fill="#009AD8" points="100,67.5 0,100 0,0" />
              </svg>
              <svg
                className="orange"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="none">
                <polygon fill="#0279A9" points="100,97.5 0,100 0,0" />
              </svg>
            </>
          )}
          <StyledhATTRLogo
            onClick={() => {
              handleCarousel('/');
              navigate('/');
            }}
          />
          {/*           {viewportWidth > 1200 && (
            <StyledAlnylamLogo>
              <AlnylamLogo />
              <p>Funded and Developed by Alnylam Pharmaceuticals</p>
            </StyledAlnylamLogo>
          )} */}
          <HCPLinkAndMenuWrapper>
            {viewportWidth > 1000 && (
              <HCPLink onClick={toggleModal}>
                <StyledHcpIcon />
                <p>
                  If you are a healthcare professional and would like further information please
                  <br />
                  <span>Click Here</span>
                </p>
              </HCPLink>
            )}
            {viewportWidth > 1200 && (
              <StyledAlnylamLogo>
                <AlnylamLogo />
                <p>Funded and Developed by Alnylam Pharmaceuticals</p>
              </StyledAlnylamLogo>
            )}
            <Menu
              onClick={toggleDropDownMenu}
              dropDownMenuIsOpen={dropDownMenuIsOpen}
              toggleConsent={this.modalRedirect}>
              <MenuIcon src={MenuIconOpenSVG} in={!dropDownMenuIsOpen} alt="menu" />
              <MenuIcon src={MenuIconCloseSVG} in={dropDownMenuIsOpen} alt="cross mark" />
              {viewportWidth > 999 && <p>Menu</p>}
            </Menu>
          </HCPLinkAndMenuWrapper>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.header`
  background-color: var(--amy-lightest-blue);
  left: 0;
  margin-left: 50%;
  max-width: 1920px;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: 2;

  &,
  & > div {
    height: 25.33vw;
    max-height: 5.938em;
  }

  > div {
    position: relative;

    @media screen and (min-width: 550px) {
      svg.yellow,
      svg.orange {
        position: absolute;
        height: 8.75em;
        top: 0;
      }

      svg.yellow {
        width: 100%;
      }

      svg.orange {
        width: 7.638888888888889%;
      }
    }
  }
`;

const StyledHeaderTrianglesMobile = styled(HeaderTrianglesMobile)`
  position: fixed;
  top: 0;
  max-width: 23.438em;
  width: 100%;
`;

const StyledCircle = styled(Circle)`
  position: absolute;
  width: 3em;
  z-index: 1;

  ${({ fill, bottom, left }) => css`
    bottom: ${bottom};
    left: ${left};

    circle {
      fill: var(${fill});
    }
  `}
`;

const StyledhATTRLogo = styled(hATTRLogo)`
  cursor: pointer;
  left: 0;
  margin-left: 5vw;
  margin-top: 5vw;
  position: absolute;
  top: 0;
  width: 38.93%;

  @media screen and (min-width: 23.438em) {
    margin-top: 1.2em;
    width: 9.125em;
  }

  @media screen and (min-width: 28.125em) {
    width: 10.25em;
  }

  @media screen and (min-width: 48em) {
    margin-top: 1.35em;
  }

  @media screen and (min-width: 62.5em) {
    margin-left: 9.03%;
    width: 10.75em;
  }

  @media screen and (min-width: 75em) {
    width: 11em;
  }

  @media screen and (min-width: 90em) {
    margin-top: 1.4em;
    width: 11em;
  }
`;

const StyledAlnylamLogo = styled.div`
  width: 10.75rem;
  padding-top: 0.5rem;
  margin-left: 0.5rem;
  svg {
    width: 8.25rem;
  }
  p {
    font-size: 0.435rem;
    line-height: 1em;
  }
`;

const HCPLinkAndMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0.938em;
  top: 0;
  width: 10.5em;
  @media screen and (min-width: 62.5em) {
    justify-content: space-between;
    right: 9.027777777777777%;
    width: 32em;
  }
  @media screen and (min-width: 75em) {
    justify-content: space-between;
    right: 9.027777777777777%;
    width: 42.125em;
  }
  @media screen and (min-width: 100em) {
    width: 44.125em;
  }
  @media screen and (min-width: 125em) {
    width: 48.125em;
  }
`;

const HCPLink = styled.button`
  box-shadow: none;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  align-items: center;
  background-color: #fff;
  box-shadow: -0.111em 0.222em 0.222em rgba(234, 85, 22, 0.25);
  color: #000;
  display: flex;
  align-items: flex-start;
  font-size: 1.125rem;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.875rem;
  height: 5rem;
  justify-content: center;
  text-decoration: none;
  width: 6.056em;
  padding: 0.75rem 1.25rem;
  span {
    font-size: 1rem;
    text-decoration: underline;
    line-height: 2rem;
  }

  @media screen and (min-width: 62.5em) {
    /* width: 23.333em; */
    width: 20rem;
  }
`;

const StyledHcpIcon = styled(HcpIcon)`
  margin-right: 1rem;
  width: 2rem;
`;

const Menu = styled.div`
  align-items: center;
  background-color: ${({ dropDownMenuIsOpen }) =>
    dropDownMenuIsOpen ? '#000' : 'var(--amy-dark-blue)'};
  box-shadow: -0.125em 0.25em 0.25em rgba(31, 52, 101, 0.25);
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 3em;
  justify-content: center;
  position: relative;
  transition: background-color 350ms ease-in-out;
  width: 3.125em;

  @media screen and (min-width: 62.5em) {
    width: 7.5em;

    p {
      font-size: 1.125rem;
      font-weight: 700;
      position: relative;
      left: 0.9em;
    }
  }
`;

const MenuIcon = transition.img.attrs({
  unmountOnExit: true,
  timeout: 350
})`
  position: absolute;
  width: 1.25em;

  @media screen and (min-width: 62.5em) {
    left: 1.25em;
  }

  &:enter {
    opacity: 0.01;
    transform: scale(1.225);
  }

  &:enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 350ms ease-in-out;
  }

  &:exit {
    opacity: 1;
    transform: scale(1.225);
  }

  &:exit-active {
    opacity: 0.01;
    transform: scale(1);
    transition: all 350ms ease-in-out;
  }
`;

export default Header;
