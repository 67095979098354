import * as React from 'react';

function SvgHeaderTrianglesMobile(props) {
  return (
    <svg viewBox="0 0 375 141" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0l375 95.175L.498 141 0 0z" fill="#009AD8" />
      <path d="M0 0l49.8 135L0 141V0z" fill="#0279A9" />
    </svg>
  );
}

export default SvgHeaderTrianglesMobile;
