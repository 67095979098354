import * as React from 'react';

function SvgFooterTrianglesMobile(props) {
  return (
    <svg viewBox="0 0 375 141" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M375 141L0 45.825 374.502 0 375 141z" fill="#009AD8" />
      <path d="M375 141L325.199 6 375 0v141z" fill="#0279A9" />
    </svg>
  );
}

export default SvgFooterTrianglesMobile;
